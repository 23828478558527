import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    userData: null,
    userStatusMessage: '',
    questions: [],  
  },
  getters: {
    userData: state => state.userData,
    questions: (state) => state.questions,
    userStatusMessage: (state) => state.userStatusMessage,
  },
  mutations: {
    setUserData(state, userData) {
      state.userData = userData; // Update the userData state
    },
    setQuestions(state, questions) {
      state.questions = questions;
    },
    SET_USER_STATUS_MESSAGE(state, message) {
      state.userStatusMessage = message;
    },
  },
  actions: {
    async fetchQuestions({ commit }) {
      try {
        const response = await fetch(`${process.env.VUE_APP_API_BASE_URL}/forms/getQuestions`);
        const data = await response.json();
        // console.log(data,'--------------------------------comming from store questions');
        commit('setQuestions', data); // Assuming `data` contains the questions array
      } catch (error) {
        console.error('Error fetching questions:', error);
      }
    },
    async loginUser({ commit }, formData) {
      console.log('FormData being sent:', formData);

      try {
        const response = await fetch(`${process.env.VUE_APP_API_BASE_URL}/user/login`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
         body: JSON.stringify(formData),
        });
        // console.log('Response Status:', response);
        if (response.ok) {
          const data = await response.json();
          console.log(data,'----------------------------------------------------');
          commit('setUserData', data); // Store the response data in Vuex
          return { success: true, message: data.message  };
        } else {
          const errorData = await response.json();
          return { success: false, message: errorData.message || 'Login failed' };
        }
      } catch (error) {
        console.error('API Error:', error);
        return { success: false, message: 'API error occurred' };
      }
    },
    async checkUser({ commit }, payload) {
      try {
        const response = await  fetch(`${process.env.VUE_APP_API_BASE_URL}/user/checkUser`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(payload),
        });

        if (!response.ok) {
          // Handle HTTP errors
          const errorData = await response.json();
          commit('SET_USER_STATUS_MESSAGE', errorData.message);
          return {
            success: false,
            message: errorData.message || 'Something went wrong!',
          };
        }

        const data = await response.json();

        // Commit the response message to state
        commit('SET_USER_STATUS_MESSAGE', data.message);

        return {
          success: true,
          message: data.message,
        };
      } catch (error) {
        // Handle network or other errors
        const errorMessage = 'Network error or server not reachable!';
        commit('SET_USER_STATUS_MESSAGE', errorMessage);

        return {
          success: false,
          message: errorMessage,
        };
      }
    },
  },
  modules: {
  }
})
