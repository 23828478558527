<template>
  <div class="content">
    <img
      src="../assets/welcomePage.jpg"
      class="full-page-image"
      alt="Full-page background"
    />
    <div class="blur-layer"></div>
    <img
      v-if="showLogoutButton"
      src="../assets/logout.png"
      alt="Logout"
      class="logout-button"
      @click="handleLogout"
    />
    <h1
      style="font-size: 35px; color: white; font-weight: 700"
      class="responsive-heading"
    >
      <!-- <span class="break">  Hello, </span><span class="break">Welcome</span> -->
      <span class="break"> নমস্কাৰ, </span><span class="break">স্বাগতম</span>
    </h1>

    <hr class="custom-hr" />
    <img
      src="../assets/Seal_of_Assam.svg.png"
      alt=""
      style="
        background-color: #00000000;
        transition: background-color 300ms;
        filter: invert(100%) sepia(0%) saturate(100%) brightness(100%)
          contrast(100%);
        width: 96px;
      "
    />
    <!-- <p style="font-size: 30px;color: rgba(255, 204, 0, 1);font-weight: 700;" class="title1">Doh Shaher, Ek Rupayan</p> -->
    <p
      style="font-size: 30px; color: rgba(255, 204, 0, 1); font-weight: 700"
      class="title1"
    >
      দহ চহৰ এক ৰূপায়ণ
    </p>

    <!-- <p style="font-size: 27px;color: white;font-weight: 500;margin-bottom: 50px;" class="title2">Sivasagar City Action Plan</p>
  <p style="font-size: 35px;color: white;font-weight: 700;" class="title3">A City Action Plan is being developed for Sivasagar</p> -->
    <p
      style="
        font-size: 27px;
        color: white;
        font-weight: 500;
        margin-bottom: 50px;
      "
      class="title2"
    >
      চহৰ কাৰ্য্য পৰিকল্পনা
    </p>
    <!-- <p style="font-size: 35px; color: white; font-weight: 700" class="title3">
      শিৱসাগৰৰ বাবে এখন চহৰ কাৰ্য্য পৰিকল্পনা প্ৰস্তুত কৰা হৈছে
    </p> -->
    <p style="font-size: 35px; color: white; font-weight: 700" class="title3">
      আপোনাৰ চহৰৰ এখন কাৰ্য্য পৰিকল্পনা প্ৰস্তুত কৰা হৈছে
    </p>
    <hr class="custom-hr" />
    <div v-if="showForm || showOtpForm" class="blur-overlay"></div>
    <!-- <b-button
      style="
        background-color: rgba(255, 204, 0, 1);
        border-radius: 33px;
        box-shadow: 0px 1px 40px 0px rgba(0, 0, 0, 0.15);
      "
      @click="toggleForm"
      ><span style="color: black; font-size: 22px; font-weight: 700"
        >Give your input</span
      ></b-button
    > -->
    <b-button
      style="
        background-color: rgba(255, 204, 0, 1);
        border-radius: 33px;
        box-shadow: 0px 1px 40px 0px rgba(0, 0, 0, 0.15);
        margin-bottom: 50px;
      "
      @click="toggleForm"
      ><span style="color: black; font-size: 22px; font-weight: 700"
        >আপোনাৰ মতামত দিয়ক</span
      ></b-button
    >
    <transition name="slide-up">
      <div v-if="showForm && !showOtpForm" class="slide-up-form">
        <div class="mb-3" style="color: black; text-align: center">
          <!-- <label style="font-size: 20px; font-weight: 700"
            >Let’s get started</label
          > -->
          <label style="font-size: 20px; font-weight: 700" class="title1s2"
            >আৰম্ভ কৰোঁ আহক</label
          >
          <button class="close-btn" @click="closeForm" aria-label="Close">
            <img src="../assets/close.png" alt="Close" class="close-icon" />
          </button>
        </div>

        <!-- Close Icon Image -->

        <form @submit.prevent="submitForm">
          <div class="mb-3" style="color: black; text-align: left">
            <!-- <label for="username" style="font-size: 15px; font-weight: 400"
              >Your Name</label
            > -->
            <label for="username" style="font-size: 15px; font-weight: 400"
              >আপোনাৰ নাম</label
            >
            <input
              type="text"
              id="username"
              v-model="username"
              class="form-control"
              @keypress="allowOnlyAlphabets"
            />
          </div>
          <div class="mb-3" style="color: black; text-align: left">
            <!-- <label for="mobile" style="font-size: 15px; font-weight: 400"
              >Mobile Number</label
            > -->
            <label
              for="mobile"
              style="font-size: 15px; font-weight: 400; margin-top: 20px"
              >মোবাইল নম্বৰ</label
            >
            <input
              type="tel"
              id="mobile"
              v-model="mobile"
              class="form-control"
              @input="validateMobileNumber"
            />
            <!-- <p v-if="mobileError" style="color: red;">{{ mobileError }}</p> -->
            <!-- <label
              for="mobile"
              style="
                font-size: 12px;
                font-weight: 400;
                color: #757575;
                text-align: center;
              "
              >we will send you an OTP on this mobile number</label
            > -->
            <label
              for="mobile"
              style="
                font-size: 12px;
                font-weight: 400;
                color: #757575;
                text-align: center;
                margin-top: 6px;
              "
              >আমি আপোনাক এই মোবাইল নম্বৰত এটা OTP পঠাম</label
            >
          </div>
          <!-- <b-button
            type="submit"
            style="
              background-color: rgba(255, 204, 0, 1);
              border-radius: 33px;
              font-size: 15px;
              font-weight: 700;
              box-shadow: 0px 1px 40px 0px rgba(0, 0, 0, 0.15);
              border: none;
              margin-top: 20px;
            "
            ><span style="color: black; padding: 10px 20px 10px 20px"
              >GET OTP</span
            ></b-button
          > -->
          <b-button
            type="submit"
            style="
              background-color: rgba(255, 204, 0, 1);
              border-radius: 33px;
              font-size: 15px;
              font-weight: 700;
              box-shadow: 0px 1px 40px 0px rgba(0, 0, 0, 0.15);
              border: none;
              margin-top: 20px;
            "
            ><span style="color: black; padding: 10px 20px 10px 20px"
              >OTP পাওক</span
            ></b-button
          >
        </form>
      </div>
    </transition>
    <!-- Sliding form container for entering OTP -->
    <transition name="slide-up">
      <div v-if="showOtpForm" class="slide-up-form">
        <div class="mb-3" style="color: black; text-align: center">
          <!-- <label style="font-size: 20px; font-weight: 700">Enter OTP</label> -->
          <label style="font-size: 20px; font-weight: 700; margin-bottom: 24px"
            >OTP প্ৰৱেশ কৰক</label
          >
          <button class="close-btn" @click="closeOtpForm" aria-label="Close">
            <img src="../assets/close.png" alt="Close" class="close-icon" />
          </button>
          <br />
          <!-- <label style="font-size: 14px; font-weight: 400">
            An OTP has been sent to XXXXXX {{ maskedMobile }}</label
          > -->
          <label style="font-size: 14px; font-weight: 400; margin-bottom: 24px">
            XXXXXX {{ maskedMobile }} লৈ এটা OTP প্ৰেৰণ কৰা হৈছে
          </label>
        </div>
        <form @submit.prevent="verifyOtp">
          <div class="mb-3">
            <!-- <label style="font-size: 20px;font-weight: 700;">Enter OTP</label> -->
            <!-- <label
              for="mobile"
              style="font-size: 15px; font-weight: 400; color: black"
              >Enter OTP</label
            > -->
            <label
              for="mobile"
              style="
                font-size: 15px;
                font-weight: 400;
                color: black;
                margin-bottom: 6px;
              "
              >OTP প্ৰৱেশ কৰক</label
            >
            <input
              type="text"
              id="otp"
              v-model="otp"
              class="form-control"
              @input="handleInput"
            />
          </div>
          <!-- <label
            for="mobile"
            style="font-size: 15px; font-weight: 400; color: black"
            >Didn't receive?
            <span style="color: rgba(255, 204, 0, 1)">Resend OTP</span>
          </label> -->
          <label
            for="mobile"
            style="
              cursor: pointer;
              font-size: 15px;
              font-weight: 400;
              color: black;
              margin-top: 20px;
              margin-bottom: 20px;
            "
            >লাভ কৰা নাই নেকি?
            <span style="color: rgba(255, 204, 0, 1)" @click="alertMessage()"
              >OTP পুনৰ প্ৰেৰণ কৰক</span
            >
          </label>
          <!-- <label style="font-size: 20px;font-weight: 700;"> Didn't receive? Resend OTP</label> -->
          <br />
          <!-- <b-button
            type="submit"
            style="
              background-color: rgba(255, 204, 0, 1);
              border-radius: 33px;
              font-size: 15px;
              font-weight: 700;
              box-shadow: 0px 1px 40px 0px rgba(0, 0, 0, 0.15);
              border: none;
            "
            ><span style="color: black"
              >Verify & Give Your Input</span
            ></b-button
          > -->
          <b-button
            type="submit"
            style="
              background-color: rgba(255, 204, 0, 1);
              border-radius: 33px;
              font-size: 15px;
              font-weight: 700;
              box-shadow: 0px 1px 40px 0px rgba(0, 0, 0, 0.15);
              border: none;
              padding: 10px 20px 10px 20px;
            "
            ><span style="color: black"
              >পৰীক্ষা কৰক আৰু আপোনাৰ মতামত দিয়ক</span
            ></b-button
          >
          <!-- <b-button type="submit" variant="success">Verify OTP</b-button> -->
        </form>
      </div>
    </transition>
    <!-- <p style="font-size: 15px; color: white; margin-top: 20px">Powered by</p> -->
    <!-- <p
      style="font-size: 15px; color: white; margin-top: 64px; line-height: 4px"
    >
      দ্বাৰা চালিত
    </p>
    <img src="../assets/jana_logo.png" alt="" /> -->
    <div v-if="loading" class="loading-overlay">
      <div class="spinner"></div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Toasted from "vue-toasted";
import Vue from "vue";
Vue.use(Toasted);
import Swal from "sweetalert2";

// @ is an alias to /src
import FingerprintJS from "@fingerprintjs/fingerprintjs";
export default {
  name: "",
  components: {},
  data() {
    return {
      showForm: false,
      showOtpForm: false,
      username: "",
      mobile: "",
      otp: "",
      fingerprint: "",
      showLogoutButton: false,
      mobileError: "",
      loading:false,
    };
  },
  mounted() {
    // Check localStorage on component load
    this.checkLocalStorage();
  },
  computed: {
    ...mapGetters(["userStatusMessage"]),
    maskedMobile() {
      // Show only the last 4 digits if mobile has at least 4 digits
      return this.mobile.length >= 4 ? this.mobile.slice(-4) : "";
    },
  },
  methods: {
    handleInput(event) {
      // Allow only digits and limit to 10 characters
      this.otp = this.otp.replace(/\D/g, "").slice(0, 4);
    },
  async  alertMessage() {
      Swal.fire({
        icon: "success",
        title: `XXXXXX${this.maskedMobile} লৈ এটা OTP প্ৰেৰণ কৰা হৈছে`,
      });
      
      this.loading = true;

      const options = {
        method: "POST",
        headers: {
          clientId: "6DKFCXBPAGCD8CTMNTQFCN29NDELVJ2D",
          clientSecret: "0ly85bh0p3xh0vlr3pd9e00mb5ht5aan",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          phoneNumber: "+91" + this.mobile,
          expiry: 30, // Expiry in minutes
          otpLength: 4, // Length of the OTP
          channels: ["SMS"], // Channels to send the OTP
          metadata: {
            appName: "CityPlan",
          },
        }),
      };

      try {
        this.loading=true;
        const response = await fetch(
          "https://auth.otpless.app/auth/v1/initiate/otp",
          options
        );

        if (!response.ok) {
          this.loading=false;
          throw new Error("Failed to send OTP. Please try again.");
         
        } else {

          const data = await response.json();
          console.log("OTP Response:", data);
          localStorage.setItem("requestId", data.requestId);
          this.loading=false;
        }
      } catch (error) {
        console.error("Error:", error);
        alert("An error occurred while sending the OTP.");
      } finally {
        this.loading = false;
      }
    },
    ...mapActions(["loginUser"]),
    ...mapActions(["checkUser"]),
    async getFingerprint() {
      // Load FingerprintJS and get the fingerprint
      const fp = await FingerprintJS.load();
      const result = await fp.get();
      this.fingerprint = result.visitorId; // Unique fingerprint
    },
    checkLocalStorage() {
      // Check if localStorage is not empty and update `showLogoutButton`
      this.showLogoutButton = localStorage.length > 0;
    },
    handleLogout() {
      // Clear localStorage

      Swal.fire({
        title: "আপুনি নিশ্চিতভাৱে লগআউট কৰিবনে?",
        // text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#3085d6",
        cancelButtonText: "নাই, লগ ইন কৰি থাকক",
        confirmButtonText: "হয়, লগআউট",
      }).then((result) => {
        if (result.isConfirmed) {
          this.showLogoutButton = false;
          localStorage.clear();
          console.log("Local storage cleared.");
        }
      });
      // Swal.fire({
      //   icon: "success",
      //   title: "Logged Out",
      //   text: "You have been logged out successfully.",
      //   confirmButtonText: "OK",
      // });
      // alert("Logged out successfully!");

      // Update state to hide the logout button
    },
    async toggleForm() {
      console.log("Toggling form...");
      this.username = "";
      this.mobile = "";
      this;
      const userData = localStorage.getItem("userDetails");
      if (userData) {
        // Parse the data if available
        const parsedData = JSON.parse(userData);

        var result = await this.loginUser(parsedData);
        if (
          result.message === "User exists" ||
          result.message === "Finger Print updated!"
        ) {
          this.$router.push({ name: "about" });
        } else {
        }
      }
      this.showForm = !this.showForm;
    },
    closeForm() {
      this.showForm = false; // Close the form and hide the overlay
    },
    closeOtpForm() {
      this.showOtpForm = false;
    },

    async submitForm() {
      if (this.username == "") {
        // If not 10 digits, show SweetAlert2 warning
        Swal.fire({
          icon: "warning",
          title: "নাম খালী হ’ব নোৱাৰে",
          // text: 'Please enter a 10-digit mobile number.',
        });
        return; // Prevent form submission
      } else if (this.mobile.length !== 10) {
        Swal.fire({
          icon: "warning",
          title: "এটা বৈধ মোবাইল নম্বৰ দিয়ক",
          // text: 'Please enter a 10-digit mobile number.',
        });
        return;
      }

      // Mock API call to send OTP
      // alert(`OTP sent to mobile number: ${this.mobile}`);
      this.showForm = false; // Hide the initial form
      const options = {
        method: "POST",
        headers: {
          clientId: process.env.VUE_APP_CLIENT_ID,
      clientSecret: process.env.VUE_APP_CLIENT_SECRET,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          phoneNumber: "+91" + this.mobile,
          expiry: 600, // Expiry in seconds
          otpLength: 4, // Length of the OTP
          channels: ["SMS"], // Channels to send the OTP
          metadata: {
            appName: "CityPlan",
          },
        }),
      };
      try {
        this.loading=true;
        const response = await fetch(
          "https://auth.otpless.app/auth/v1/initiate/otp",
          options
        );

        if (!response.ok) {
          this.loading=false;
          throw new Error("Failed to send OTP. Please try again.");
         
        } else {

          const data = await response.json();
          console.log("OTP Response:", data);
          localStorage.setItem("requestId", data.requestId);
          this.loading=false;
        }
      } catch (error) {
        console.error("Error:", error);
        alert("An error occurred while sending the OTP.");
      } finally {
        this.loading = false;
      }
      this.showOtpForm = true; // Show the OTP form
    },
    async verifyOtp() {
      const userId = localStorage.getItem("requestId");
      console.log("User:", userId);
      if (this.otp === "") {
        Swal.fire({
          icon: "error", // Use error icon for invalid cases
          title: "অ’টিপি খালী হ’ব নোৱাৰে",

          confirmButtonText: "OK",
        });
      } 
     
      else if ((this.otp!="") && (this.otp!=null) && (userId)){
        this.loading = true;
        if (!this.fingerprint) {
          await this.getFingerprint();
        }
        const payload = {
          user_name: this.username,
          mobile_number: this.mobile,
          browser_fingerprint: this.fingerprint,
        };
        // console.log(JSON.stringify(payload), "this is password");
        const options = {
        method: "POST",
        headers: {
          clientId: process.env.VUE_APP_CLIENT_ID,
          clientSecret: process.env.VUE_APP_CLIENT_SECRET,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          requestId: localStorage.getItem("requestId"), // Retrieve the stored request ID
          otp: this.otp,
        }),
      };
      try {
        const response = await fetch(
          "https://auth.otpless.app/auth/v1/verify/otp",
          options
        );
        if (!response.ok) {
          this.loading = false;
          throw new Error("OTP verification failed.");
        }

        const data = await response.json();
        console.log("Verify OTP Response:", data);
        if(data){
          const result = await this.loginUser(payload);
          localStorage.setItem(
            "userDetails",
            JSON.stringify({
              user_name: this.username,
              mobile_number: this.mobile,
              browser_fingerprint: payload.browser_fingerprint,
            })
          );
          this.loading = false;
          //  alert(result.message);
          this.$router.push({ name: "about" });
        }

      } catch (error) {
        this.loading = false;
        Swal.fire({
          icon: "error", // Use error icon for invalid cases
          title: "প্ৰৱেশ কৰা অ’টিপি অবৈধ বা মেয়াদ উকলি গৈছে ",
          confirmButtonText: "OK",
        });
      }
       
      } 
      else {
        Swal.fire({
          icon: "error", // Use error icon for invalid cases
          title: "প্ৰৱেশ কৰা অ’টিপি অবৈধ বা মেয়াদ উকলি গৈছে ",

          confirmButtonText: "OK",
        });
      }
    },
    allowOnlyAlphabets(event) {
      // Prevent any key that is not a letter (a-z or A-Z)
      const charCode = event.charCode;
      if (
        !(charCode >= 65 && charCode <= 90) && // Uppercase A-Z
        !(charCode >= 97 && charCode <= 122) // Lowercase a-z
      ) {
        event.preventDefault();
      }
    },
    validateMobileNumber() {
      // Allow only digits and limit to 10 characters
      this.mobile = this.mobile.replace(/\D/g, "").slice(0, 10);
      // Check if the entered mobile number has exactly 10 digits
      if (this.mobile.length < 10) {
        this.mobileError = "Please enter 10 digits"; // Show error message
      } else {
        this.mobileError = ""; // Clear error message when 10 digits are entered
      }
    },
  },
};
</script>
<style scoped>
.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  backdrop-filter: blur(5px);
}

.spinner {
  border: 8px solid #f3f3f3;
  border-top: 8px solid #FFCC00;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.full-page-image {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1;
}

.blur-layer {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(78, 78, 78, 0.01); /* Adjust color and transparency */
  backdrop-filter: blur(10px); /* Apply blur effect */
  z-index: -1; /* Make sure it's above the image but below content */
}

.content {
  position: relative;
  z-index: 1; /* Ensure content appears above the blur layer */
  color: #ffffff; /* Adjust text color for visibility */
  text-align: center;
  padding-top: 5%;
}
.logout-button {
  position: absolute; /* Use absolute positioning */
  top: 20px; /* Adjust top margin */
  right: 20px; /* Adjust right margin */
  width: auto; /* Set a fixed size for the button */
  height: auto;
  cursor: pointer; /* Make it clear this is clickable */
}
.custom-hr {
  width: 80%; /* Sets width to 80% of the container */
  margin: 20px auto; /* Centers the line and adds spacing */
  border: none; /* Removes default border style */
  border-top: 3px solid rgba(224, 197, 39, 1); /* Customizes the thickness and color */
}
/* Slide-up animation for the form container */
/* Slide-up transition styles */
.slide-up-enter-active,
.slide-up-leave-active {
  transition: transform 0.3s ease-in-out;
}
.slide-up-enter,
.slide-up-leave-to {
  transform: translateY(100%);
}

/* Styling for the form container */
.slide-up-form {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 20px;
  background-color: #fff;
  box-shadow: 0 -2px 8px rgba(0, 0, 0, 0.3);
  z-index: 1000;
  border-radius: 15px 15px 0px 0px;
}
/* Blur overlay effect */
.blur-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(10px); /* Apply blur effect */
  background-color: rgba(0, 0, 0, 0.3); /* Semi-transparent background */
  z-index: 1000; /* Below the form but above the content */
}
/* Styling for the close button */
.close-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  background: transparent;
  border: none;
  padding: 0;
  cursor: pointer;
}

.close-btn:hover .close-icon {
  opacity: 0.7; /* Slightly fade the icon on hover */
}

/* Styling for the close icon image */
.close-icon {
  width: 80px;
  height: 80px;
}
</style>
<style>
.responsive-heading .break {
  margin-top: 0px !important;
  display: inline; /* Default to inline for larger screens */
}

@media (max-width: 768px) {
  .title1s2 {
    margin-bottom: 24px;
  }
  .responsive-heading .break {
    display: block; /* Forces a line break for screens 768px or smaller */
    font-size: 27px;
    text-align: left;
    padding-left: 20px;
    margin-top: 20px;
    margin-bottom: 24px;
  }
  .custom-hr {
    margin-bottom: 24px !important;
    width: 90%;
  }
  .title1 {
    font-size: 32px !important;
    margin-top: 24px;
  }
  .title2 {
    font-size: 24px !important;
  }
  .title3 {
    text-align: left;
    padding-left: 20px;
    margin-bottom: 20px;
    margin-top: 87px;
  }
}
</style>
